// eslint-disable-next-line no-unused-vars
import { Close as CloseIcon, MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
	AppBar,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Toolbar,
	Typography,
} from "@mui/material";
import { useState } from "react";
import BARLogo from "../../../images/logo/BAR-logo.svg";
import About from "./components/about/About";
import Feedback from "./components/feedback/Feedback";
import Publications from "./components/publications/Publications";
import SearchBar from "./components/search-bar/SearchBar";
import "./nav-bar.css";

/**
 * Navbar component
 * @returns {JSX.Element} Navbar component
 */
export default function NavBar() {
	/** Open kebab menu for more options */
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);
	/** Whether to open the dialog [true] or not [false, default] */
	const [open, setOpen] = useState(false);
	/** Which dialog will open */
	const [whichModal, setWhichModal] = useState("");
	/** What the dialog content will be */
	const [modalContent, setModalContent] = useState(null);
	/** Close dialog */
	const handleClose = () => setOpen(false);

	const handleClickMenu = (target) => {
		setAnchorEl(target);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	/**
	 * Handle the opening of the dialog
	 * @param {String} which Which dialog should open
	 */
	function handleOpen(which) {
		if (which?.toLowerCase().trim() === "about") {
			setWhichModal("About");
			setModalContent(<About key="about" />);
		} else if (which?.toLowerCase().trim() === "publications") {
			setWhichModal("BAR Publications");
			setModalContent(<Publications key="publications" />);
		} else if (which?.toLowerCase().trim() === "feedback") {
			setWhichModal("Feedback");
			setModalContent(<Feedback key="feedback" />);
		} else {
			setModalContent(null);
		}

		if (which) {
			setOpen(true);
		}
	}

	return (
		<>
			{/* Nav bar */}
			<AppBar position="static" className="navbar navbar-default" id="navbar">
				<Toolbar className="navbar-toolbar">
					<Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
						<Grid item>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
								{/* Logo */}
								<a aria-label="Back to BAR homepage" href="https://bar.utoronto.ca/">
									<img
										alt="BAR's logo"
										className="logo"
										fetchpriority="high"
										id="logo"
										loading="auto"
										src={BARLogo}
									/>
								</a>

								{/* Title */}
								<a aria-label="Back to BAR homepage" href="https://bar.utoronto.ca/">
									<Typography className="navbar-brand navbar-header">
										The Bio-Analytic Resource for Plant Biology
									</Typography>
								</a>
							</Stack>
						</Grid>

						{/* Search */}
						<Grid item sx={{ flexGrow: 1 }}>
							<SearchBar />
						</Grid>

						{/* Kebab menu */}
						<Grid>
							<IconButton
								aria-controls="menu-appbar"
								aria-expanded={openMenu ? "true" : undefined}
								aria-haspopup="true"
								aria-label="additional options"
								className="navbar-menu"
								id="menu-button"
								onClick={(event) => {
									handleClickMenu(event.currentTarget);
								}}
							>
								<MoreVertIcon />
							</IconButton>
						</Grid>
					</Grid>

					<Menu
						MenuListProps={{ "aria-labelledby": "menu-button" }}
						anchorEl={anchorEl}
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						elevation={0}
						id="menu-appbar"
						onClose={handleCloseMenu}
						open={openMenu}
						transformOrigin={{ vertical: "top", horizontal: "right" }}
					>
						{/* About */}
						<MenuItem
							key="about-menu"
							onClick={() => {
								handleOpen("About");
								handleCloseMenu();
							}}
						>
							<span className="navbar-brand">About</span>
						</MenuItem>

						{/* Publications */}
						<MenuItem
							key="publications-menu"
							onClick={() => {
								handleOpen("Publications");
								handleCloseMenu();
							}}
						>
							<span className="navbar-brand">Publications</span>
						</MenuItem>

						{/* Feedback */}
						<MenuItem
							key="feedback-menu"
							onClick={() => {
								handleOpen("Feedback");
								handleCloseMenu();
							}}
						>
							<span className="navbar-brand">Feedback</span>
						</MenuItem>

						{/* Help */}
						<MenuItem key="help-menu">
							<a aria-label="Get help" href="https://bar.utoronto.ca/affydb/BAR_instructions.html">
								<span className="navbar-brand">Help</span>
							</a>
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>

			{/* Dialog */}
			<Dialog open={open || false} onClose={handleClose} maxWidth="md">
				<DialogTitle id="dialog-title">
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Typography variant="h4">{whichModal}</Typography>
						</Grid>
						<Grid item>
							<IconButton aria-label="close" className="close-button" onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent dividers key="dialog-content-navbar">
					{modalContent}
				</DialogContent>
			</Dialog>
		</>
	);
}
