import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import eFPWheat from "../../../images/apps/Wheat eFP Browser.webp";
import ePlant from "../../../images/apps/ePlant.webp";
import GCBRLogo from "../../../images/news/GCBR-Logo-RGB.webp";
import "./news.css";

/**
 * News component
 * @returns {JSX.Element} News component
 */
export default function News() {
	return (
		<div className="news">
			<h2 className="news-heading">BAR News & Other Announcements</h2>
			<p className="news-description">
				Follow us on Facebook to receive occasional updates about the BAR:
				<a href="https://www.facebook.com/BioAnalyticResource" target="_blank" rel="noopener noreferrer">
					{" "}
					facebook.com/BioAnalyticResource
				</a>
			</p>
			<Grid container direction="row" justifyContent="space-evenly" alignItems="stretch" spacing={3}>
				{/* The following section is written manually to let you have more control */}

				{/* Funding information */}
				<Grid item xs={12} sm={6} md={3}>
					<Card>
						<CardHeader title="Funding News" />
						<CardContent>
							<p>
								We are grateful to The Faculty of Arts and Science at the University of Toronto for
								funding an RA position to help coordinate research project internships at the BAR and
								for the BAR&apos;s ThaleMine instance, which the BAR team rescued after the demise of
								Araport. Thanks, too, to NSERC and Genome Canada support to the Provart Lab to help fund
								the BAR&rsquo;s operations.
							</p>
						</CardContent>
					</Card>
				</Grid>

				{/* eFP Wheat Browser */}
				<Grid item xs={12} sm={6} md={3}>
					<Card>
						<CardHeader title="eFP Wheat" />
						<CardContent>
							<img
								alt="Thumbnail for the What eFP Browser"
								className="news-images"
								loading="auto"
								src={eFPWheat}
							/>
							<p>
								Try our new{" "}
								<a href="https://bar.utoronto.ca/efp_wheat/cgi-bin/efpWeb.cgi">Wheat eFP Browser</a>,
								published together an extensive analysis of gene expression of homeologous genes in
								wheat with Cristobal Uauy and colleagues in{" "}
								<a
									href="https://science.sciencemag.org/content/361/6403/eaar6089"
									target="_blank"
									rel="noopener noreferrer"
								>
									Science Magazine
								</a>
								!
							</p>
						</CardContent>
					</Card>
				</Grid>

				{/* ePlant */}
				<Grid item xs={12} sm={6} md={3}>
					<Card>
						<CardHeader title="ePlant" />
						<CardContent>
							<img loading="auto" src={ePlant} alt="Thumbnail for ePlant" className="news-images" />
							<p>
								<a href="https://bar.utoronto.ca/eplant/">ePlant</a> helps biologists visualize the
								natural connections between DNA sequences, natural variation (polymorphisms), molecular
								structures, protein-protein interactions, and gene expression patterns by combining
								several data visualization tools with a zoomable user interface{" "}
								<a
									href="https://www.plantcell.org/content/29/8/1806"
									target="_blank"
									rel="noopener noreferrer"
								>
									Waese <i>et al.</i> 2017
								</a>
								.
							</p>
						</CardContent>
					</Card>
				</Grid>

				{/* Facebook Thank you */}
				<Grid item xs={12} sm={6} md={3}>
					<Card>
						<CardHeader title="Thank you!" />
						<CardContent>
							<img alt="GCBR thumbnail" className="news-images" loading="auto" src={GCBRLogo} />
							<p>
								The BAR was designated as a Global Core Biodata Resource in 2023 by the Global Biodata
								Coalition. GCBRs provide free and open access to data, are used extensively, are mature
								and comprehensive, are considered authoritative in their field, are of high scientific
								quality, and provide a professional standard of service delivery.
							</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
}
